import { types } from '@/lib/data/validator/ValidatorRules'

export default {
  Accepted                 : types.accepted.name,
  Alpha                    : types.alpha.name,
  AlphaNum                 : types.alphaNum.name,
  Numeric                  : types.numeric.name,
  Array                    : types.array.name,
  Object                   : types.object.name,
  Function                 : types.function.name,
  Promise                  : types.promise.name,
  Boolean                  : types.boolean.name,
  Number                   : types.number.name,
  Integer                  : types.integer.name,
  Float                    : types.float.name,
  String                   : types.string.name,
  Regex                    : types.regex.name,
  Url                      : types.url.name,
  Domain                   : types.domain.name,
  Vat                      : types.vat.name,
  Email                    : types.email.name,
  Date                     : types.date.name,
  IP                       : types.ip.name,
  IPv4                     : types.ipv4.name,
  IPv6                     : types.ipv6.name,
  MysqlDateTimeString      : types.mysqlDateTimeString.name,
  MysqlDateOrDateTimeString: types.mysqlDateOrDateTimeString.name,
  MysqlDateString          : types.mysqlDateString.name,
  MysqlTimeString          : types.mysqlTimeString.name,
  TimeString               : types.timeString.name,
  DateStringDash           : types.dateStringDash.name,
  DateStringSlash          : types.dateStringSlash.name
}
