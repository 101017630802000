import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.languages.length > 1)?_c('div',[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"name":_vm.$t('Common.Misc.Locale'),"dark":"","depressed":"","icon":"","small":""}},on),[_c(VAvatar,{attrs:{"size":_vm.size}},[_c(VImg,{attrs:{"src":_vm.lang.icon}})],1)],1)]}}],null,false,3403456864)},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.languages),function(lng){return _c(VListItem,{key:lng.locale,attrs:{"disabled":lng.locale === _vm.lang.locale},on:{"click":function($event){return _vm.switchLanguage(lng)}}},[_c(VListItemAvatar,{attrs:{"size":_vm.size}},[_c('img',{attrs:{"src":lng.icon}})]),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(lng.name))])],1)],1)}),1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }