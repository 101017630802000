<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="700px"
      persistent
      scrollable
    >
      <v-card v-if="model">
        <v-toolbar
          flat
          height="80"
        >
          <v-toolbar-title>
            <div class="text-size-32 synthesis-brand-dark-blue--text pl-4">
              {{ $t('Contact.Form.Title') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            :disabled="isFormSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <template
            v-if="errorMessage"
            #extension
          >
            <v-alert
              class="text-left fill-width ml-4 mr-6"
              color="error"
              dense
              text
              type="error"
            >
              {{ errorMessage }}
            </v-alert>
          </template>
        </v-toolbar>

        <v-card-text
          ref="formContainer"
          class="pt-5"
        >
          <v-form
            ref="form"
            v-model="isFormValid"
          >
            <v-container fluid>
              <v-row>
                <v-col
                  class="py-0"
                  cols="12"
                >
                  <label class="field-label">
                    {{ $t('Contact.Form.Fields.ContactName.Label') }}
                  </label>
                  <v-text-field
                    v-model="model.contactName"
                    :error-messages="serverErrorMessages['contactName']"
                    :placeholder="$t('Contact.Form.Fields.ContactName.Placeholder')"
                    :readonly="isFormSaving"
                    :rules="model.validator.vuetifyFormFieldRules('contactName')"
                    outlined
                    type="text"
                  />
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                >
                  <label class="field-label">
                    {{ $t('Contact.Form.Fields.Email.Label') }}
                  </label>
                  <v-text-field
                    v-model="model.email"
                    :error-messages="serverErrorMessages['email']"
                    :placeholder="$t('Contact.Form.Fields.Email.Placeholder')"
                    :readonly="isFormSaving"
                    :rules="model.validator.vuetifyFormFieldRules('email')"
                    outlined
                    type="email"
                  />
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                >
                  <label class="field-label">
                    {{ $t('Contact.Form.Fields.ContactNumber.Label') }}
                  </label>
                  <v-text-field
                    v-model="model.contactNumber"
                    v-mask="`##############`"
                    :error-messages="serverErrorMessages['contactNumber']"
                    :placeholder="$t('Contact.Form.Fields.ContactNumber.Placeholder')"
                    :readonly="isFormSaving"
                    :rules="model.validator.vuetifyFormFieldRules('contactNumber')"
                    outlined
                    type="text"
                  />
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                >
                  <label class="field-label">
                    {{ $t('Contact.Form.Fields.Subject.Label') }}
                  </label>
                  <v-text-field
                    v-model="model.subject"
                    :error-messages="serverErrorMessages['subject']"
                    :placeholder="$t('Contact.Form.Fields.Subject.Placeholder')"
                    :readonly="isFormSaving"
                    :rules="model.validator.vuetifyFormFieldRules('subject')"
                    outlined
                    type="text"
                  />
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                >
                  <label class="field-label">
                    {{ $t('Contact.Form.Fields.Message.Label') }}
                  </label>
                  <v-textarea
                    v-model="model.message"
                    :error-messages="serverErrorMessages['message']"
                    :placeholder="$t('Contact.Form.Fields.Message.Placeholder')"
                    :readonly="isFormSaving"
                    :rules="model.validator.vuetifyFormFieldRules('message')"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />
        <v-card-actions>
          <div class="flex-grow-1" />

          <v-btn
            :disabled="isFormSaving"
            class="synthesis-text text-size-14 font-weight-medium"
            large
            min-width="130"
            plain
            tile
            @click="closeDialog"
          >
            {{ $t('Common.Button.Cancel') }}
          </v-btn>

          <v-btn
            :disabled="!isFormValid || isFormSaving"
            :loading="isFormSaving"
            class="synthesis-text text-size-14 font-weight-medium white--text"
            color="synthesis-ui-green-02"
            depressed
            large
            min-width="130"
            @click="submitForm"
            v-text="$t('Common.Button.Submit')"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { isBoolean } from '@/lib/utils/type'
import dialogVisible from '@/mixins/dialog/dialogVisible'
import dialogData    from '@/mixins/dialog/dialogData'
import ContactModel  from '@/api/models/contact/ContactModel'
import API           from '@/api/Api'

export default {
  name      : 'ContactDialog',
  components: {},
  directives: {},
  mixins    : [dialogVisible, dialogData],
  props     : {},
  enums     : {},
  dataModel : ContactModel,
  data () {
    return {
      errorMessage: ''
    }
  },
  computed: {},
  watch   : {
    isVisible () {
      this.errorMessage = ''
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    ...{ isBoolean },

    submitForm () {
      if (this.isFormSaving || !this.validateForm()) return
      this.errorMessage = ''
      this.isFormSaving = true

      this.model.SubmitForm()
          .then(response => {
            this.handleResponse(response)
          })
          .catch(error => {
            this.handleResponse(error.response)
          })
          .finally(() => {
            this.isFormSaving = false
          })
    },

    handleResponse (response) {
      const data = API.responseData(response) || null
      // const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.formSaveSuccess(data)
      } else {
        this.errorMessage = this.$t('Common.Error.Generic.Title')
      }
    }
  }
}
</script>

<style scoped>

</style>
