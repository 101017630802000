export default {
  Home: {
    Title: '@:Home.Title',
    Icon : '',
    Meta : {
      Title: '@:Route.Home.Title',
      Desc : '',
      Img  : ''
    }
  }
}
