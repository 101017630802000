<template>
  <v-app>
    <app-toolbar />

    <v-main class="synthesis-brand-dark-blue">
      <v-container
        class="pa-0 pa-sm-3"
        fluid
      >
        <v-row dense>
          <v-col cols="12">
            <slot />
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <app-footer />
  </v-app>
</template>

<script>
import AppToolbar from '@/theme/default/components/app/AppToolbar'
import AppFooter  from '@/theme/default/components/app/AppFooter'

export default {
  name      : 'DefaultLayout',
  components: {
    AppFooter,
    AppToolbar
  },
  mixins   : [],
  dataStore: {},
  data     : function () {
    return {}
  },
  computed: {},
  watch   : {},
  created () {},
  mounted () {},
  beforeDestroy () {},
  methods : {}
}

</script>

<style>

</style>
