import BaseModel        from '@/lib/data/model/BaseModel'
import ContactValidator from '@/api/validations/contact/ContactValidator'
import ContactResource  from '@/api/resources/contact/ContactResource'
import API              from '@/api/Api'

export default class ContactModel extends BaseModel {
  constructor (payload = {}, resource = ContactResource, validator = ContactValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */

  async SubmitForm () {
    return await API.Resource.Contact.SubmitForm(this.clone())
  }
}
