export default {
  App: {
    Title: 'Maven Synthesis',
    Desc : '',
    Img  : ''
  },

  Button: {
    Login     : 'Login',
    Submit    : 'Submit',
    Cancel    : 'Cancel',
    GetStarted: 'Get Started'
  },

  Error: {
    Generic: {
      Title  : 'An error occurred. Please try again later.',
      Message: 'An unexpected error has occurred'
    },
    Staging    : 'Attention! This is a DEMO environment.',
    Development: 'Attention! This is a DEV environment.'
  }
}
