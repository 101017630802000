import BaseResource from '@/lib/data/resource/BaseResource'

export default class ContactResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.contactName = payload.contactName ?? ''
    this.contactNumber = payload.contactNumber ?? ''
    this.email = payload.email ?? ''
    this.subject = payload.subject ?? ''
    this.message = payload.message ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
