/**
 * Converts a number in bytes to a human-readable string.
 *
 * Use an array dictionary of units to be accessed based on the exponent. Use Number.toPrecision() to truncate the number to a certain number of digits.
 * Return the prettified string by building it up, taking into account the supplied options and whether it is negative or not. Omit the second argument,
 * precision, to use a default precision of 3 digits. Omit the third argument, addSpace, to add space between the number and unit by default.
 *
 * @example prettyBytes(1000); // "1 KB"
 * @example prettyBytes(-27145424323.5821, 5); // "-27.145 GB"
 * @example prettyBytes(123456789, 3, false); // "123MB"
 *
 * @param num
 * @param precision
 * @param addSpace
 * @returns {string}
 */
import i18n from '@/lang/lang'

export const prettyBytes = (num, precision = 3, addSpace = true) => {
  if (!num) return ''

  const UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  if (Math.abs(num) < 1) return num + (addSpace ? ' ' : '') + UNITS[0]
  const exponent = Math.min(Math.floor(Math.log10(num < 0 ? -num : num) / 3), UNITS.length - 1)
  const n = Number(((num < 0 ? -num : num) / 1000 ** exponent).toPrecision(precision))
  return (num < 0 ? '-' : '') + n + (addSpace ? ' ' : '') + UNITS[exponent]
}

/**
 * Take a number and return specified currency formatting.
 *
 * Use Intl.NumberFormat to enable country / currency sensitive formatting.
 *
 * @example toCurrency(123456.789, 'EUR'); // €123,456.79  | currency: Euro | currencyLangFormat: Local
 * @example toCurrency(123456.789, 'USD', 'en-us'); // $123,456.79  | currency: US Dollar | currencyLangFormat: English (United States)
 * @example toCurrency(123456.789, 'USD', 'fa'); // ۱۲۳٬۴۵۶٫۷۹ ؜$ | currency: US Dollar | currencyLangFormat: Farsi
 * @example toCurrency(322342436423.2435, 'JPY'); // ¥322,342,436,423 | currency: Japanese Yen | currencyLangFormat: Local
 * @example toCurrency(322342436423.2435, 'JPY', 'fi'); // 322 342 436 423 ¥ | currency: Japanese Yen | currencyLangFormat: Finnish
 *
 * @param n
 * @param curr
 * @param LanguageFormat
 * @returns {*}
 */
export const toCurrency = (n, curr, LanguageFormat = i18n.code) => Intl.NumberFormat(LanguageFormat, {
  style   : 'currency',
  currency: curr
}).format(n || 0)

/**
 * Use toLocaleString() to convert a float-point arithmetic to the Decimal mark form. It makes a comma separated string from a number.
 *
 * @example toDecimalMark(12305030388.9087); // "12,305,030,388.909"
 *
 * @param num
 * @param locale
 * @param decimals
 * @returns {string}
 */
export const toDecimalMark = (num, decimals = 2, locale = i18n.code) => {
  return num ? num.toLocaleString(locale, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }) : ''
}

export const toDecimalSignificant = (num, decimals = 3, locale = i18n.code) => {
  return num ? num.toLocaleString(locale, {
    maximumSignificantDigits: decimals
  }) : ''
}

/**
 * Use toLocaleString() to convert a float-point arithmetic to the Decimal mark form. It makes a comma separated string from a number.
 *
 * @example toDecimalMarkRange(12305030388.9087); // "12,305,030,388.909"
 *
 * @param num
 * @param locale
 * @param minDecimals
 * @param maxDecimals
 * @returns {string}
 */
export const toDecimalMarkRange = (num, minDecimals = 0, maxDecimals = 2, locale = i18n.code) => {
  return num ? num.toLocaleString(locale, {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals
  }) : ''
}

/**
 * Compact Notation
 *
 * Compact notation rounding strategy:
 * round to the nearest integer, but always keep 2 significant digits.
 *
 * For example, 123.4K rounds to 123K, and 1.234K rounds to 1.2K
 *
 * Reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 *
 * @param num
 * @param compactDisplay takes either "short" (default) or "long"
 * @param locale
 * @returns {string}
 */
export const toCompactNotation = (num, compactDisplay = 'short', locale = i18n.code) => num ? num.toLocaleString(locale, {
  notation      : 'compact',
  compactDisplay: compactDisplay
}) : ''

export const toNumberFormatNotationShort = (num, lower = true, locale = i18n.code) => {
  if (!num) return ''

  const o = num.toLocaleString(locale, {
    notation                : 'compact',
    compactDisplay          : 'short',
    maximumSignificantDigits: 3
  })

  return lower ? o.toLocaleLowerCase(locale) : o
}

export const toNumberFormatNotationLong = (num, lower = true, locale = i18n.code) => {
  if (!num) return ''

  const o = num.toLocaleString(locale, {
    notation                : 'compact',
    compactDisplay          : 'long',
    maximumSignificantDigits: 3
  })

  return lower ? o.toLocaleLowerCase(locale) : o
}

/**
 * Adds an ordinal suffix to a number
 *
 * Use the modulo operator (%) to find values of single and tens digits.
 * Find which ordinal pattern digits match.
 * If digit is found in teens pattern, use teens ordinal.
 *
 * @example toOrdinalSuffix('123'); // "123rd"
 *
 * @param num
 * @returns {string}
 */
export const toOrdinalSuffix = num => {
  if (!num) return ''

  const int = parseInt(num)
  const digits = [int % 10, int % 100]
  const ordinals = ['st', 'nd', 'rd', 'th']
  const oPattern = [1, 2, 3, 4]
  const tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19]
  return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
    ? int + ordinals[digits[0] - 1]
    : int + ordinals[3]
}

/**
 * Returns true if the string is y/yes or false if the string is n/no.
 *
 * Use RegExp.test() to check if the string evaluates to y/yes or n/no. Omit the second argument, def to set the default answer as no.
 *
 * @example yesNo('Y'); // true
 * @example yesNo('yes'); // true
 * @example yesNo('No'); // false
 * @example yesNo('Foo', true); // true
 *
 * @param val
 * @param def
 * @returns {boolean}
 */
export const yesNo = (val, def = false) => /^(y|yes)$/i.test(val) ? true : /^(n|no)$/i.test(val) ? false : def

/**
 * Returns cloned object
 *
 * @example clone([1, 2, 3]); // [1, 2, 3]
 *
 * @param obj
 * @returns {any}
 */
export const clone = obj => JSON.parse(JSON.stringify(obj))
