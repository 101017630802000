export default {
  Title: 'Maven Synthesis',

  Block: {
    LiveRisk: {
      Title       : '',
      SubTitle    : '',
      CallToAction: ''
    }
  }
}
