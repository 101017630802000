import Vue                       from 'vue'
import Storage                   from 'vue-web-storage'
import VueMeta                   from 'vue-meta'
import VueMask                   from 'v-mask'
import App                       from '@/App'
import vuetify                   from '@/plugins/vuetify'
import { resetRouter, router }   from '@/router'
import { i18n, setI18nLanguage } from '@/lang/lang'
import DataStorePlugin           from '@/lib/data/dataStore/plugin/DataStorePlugin'
import EventBus                  from '@/lib/events/eventBus'
import Enums                     from '@/lib/enums/plugin'
import { themeUtils }            from '@/lib/utils'
import DayjsPlugin               from '@/lib/date/plugin'
import EnvMixin                  from '@/mixins/env'
import LangMiddleware            from '@/router/middleware/LangMiddleware'
import StoreRouteMiddleware      from '@/router/middleware/StoreRouteMiddleware'
import RouteMiddleware           from '@/lib/middleware/RouteMiddleware'
import RouterReadyMiddleware     from '@/router/middleware/RouterReadyMiddleware'
import DataStore                 from '@/lib/data/dataStore/DataStore'
import { setupGoogleAnalytics }  from '@/lib/services/GoogleAnalytics'
import '@mdi/font/css/materialdesignicons.css'
import '@openfonts/roboto_greek'
import './App.css'

const DefaultLayout = () => themeUtils.importThemeComponent('layouts/DefaultLayout')

const initVue = () => {
  resetRouter()

  Vue.component('DefaultLayout', DefaultLayout)

  Vue.mixin(EnvMixin)

  Vue.use(EventBus)
  Vue.use(DataStorePlugin)
  Vue.use(DayjsPlugin)
  Vue.use(Enums)
  Vue.use(VueMask)
  Vue.use(Storage, {
    prefix : `${ process.env.VUE_APP_STORAGE_PREFIX }_`,
    drivers: ['session', 'local']
  })
  Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
  })

  window.Vue = new Vue({
    el        : '#app',
    directives: {},
    mixins    : [],

    data () {
      return {}
    },

    beforeMount () {
      setI18nLanguage(process.env.VUE_APP_I18N_LOCALE)
      this.$router.onReady(RouterReadyMiddleware)
      router.beforeEach(RouteMiddleware({
        LangMiddleware
      }, true, this))
      router.afterEach(RouteMiddleware({ StoreRouteMiddleware }, true, this))
    },

    beforeCreate () {},

    created () {},

    mounted () {
      this.onInitAnalytics()
    },

    beforeDestroy () {},

    methods: {
      onInitAnalytics () {
        const defaultEnvironments = ['production', 'staging']

        setupGoogleAnalytics({
          enabled     : process.env.VUE_APP_GA_ENABLED === 'true',
          key         : process.env.VUE_APP_GA_PROPERTY_KEY,
          environments: defaultEnvironments
        }, router)
      }
    },
    i18n,
    router,
    vuetify,
    render: h => h(App)
  })

  if (process.env.NODE_ENV === 'production') {
    Vue.config.productionTip = false
    Vue.config.devtools = false
    Vue.config.debug = false
    Vue.config.silent = true
  }
}

const init = (data = {}) => {
  DataStore.App.Config = { ...(DataStore?.App?.Config ?? {}), ...(data?.data ?? {}) }
  initVue()
}

init()
