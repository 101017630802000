import { API } from '@/api/Api'

export default {

  async SubmitForm (data = {}) {
    const Endpoint = API.Endpoint.Contact.SubmitForm
    const Data = data
    return await API.RESTRequest(Endpoint, Data)
  }

}
