export default {
  Title: 'Get Started',

  Form: {
    Title : 'How can we help?',
    Fields: {
      ContactName: {
        Label      : 'Name',
        Placeholder: 'Enter your name'
      },
      Email: {
        Label      : 'Email',
        Placeholder: 'Enter your email'
      },
      ContactNumber: {
        Label      : 'Contact Number',
        Placeholder: 'Enter your contact number'
      },
      Subject: {
        Label      : 'Subject',
        Placeholder: 'Enter subject'
      },
      Message: {
        Label      : 'Message',
        Placeholder: 'What can we help you with?'
      }
    }
  }
}
