/* eslint-disable no-misleading-character-class */

import * as typeUtils                       from '@/lib/utils/type'
import { pathToValue, sortObjectDeepByKey } from '@/lib/utils/object'

export const types = {
  accepted: value => isNotEmpty('', value) && value !== 0,

  alpha: value => (new RegExp(/^[a-zA-Zα-ωίϊΐόάέύϋΰήώΑ-ΩΊΪΪ́ΌΆΈΎΫΫΉΏ]*$/)).test(value),

  alphaNum: value => (new RegExp(/^[a-zA-Zα-ωίϊΐόάέύϋΰήώΑ-ΩΊΪΪ́ΌΆΈΎΫΫΉΏ0-9]*$/)).test(value),

  numeric: value => (new RegExp(/^[0-9]*$/)).test(value),

  array: value => typeUtils.isArray(value),

  object: value => typeUtils.isObject(value),

  function: value => typeUtils.isFunction(value),

  promise: value => typeUtils.isPromiseLike(value),

  boolean: value => typeUtils.isBoolean(value),

  number: value => typeUtils.isNumber(value),

  integer: value => typeUtils.isNumber(value) && Number(value) === parseInt(value) && Number(value) % 1 === 0,

  float: value => typeUtils.isNumber(value) && Number(value) === parseFloat(value) && Number(value) % 1 !== 0,

  string: value => typeUtils.isString(value),

  regex: value => ![undefined, null].includes(value) && value.constructor === RegExp,

  url: value => (new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$', 'i')).test(value),

  domain: value => {
    if (typeof value !== 'string') return false
    const opts = {}

    const parts = value.split('.')
    if (parts.length <= 1) return false

    const tld = parts.pop()
    const tldRegex = /^(?:xn--)?[a-zA-Z0-9]+$/gi

    if (!tldRegex.test(tld)) return false
    if (opts.subdomain === false && parts.length > 1) return false

    return parts.every(function (host, index) {
      if (opts.wildcard && index === 0 && host === '*' && parts.length > 1) return true

      const hostRegex = /^(?!:\/\/)([a-zA-Z0-9]+|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])$/gi

      return hostRegex.test(host)
    })
  },

  /*
    ^(
    (AT)?U[0-9]{8} |                              # Austria
    (BE)?0[0-9]{9} |                              # Belgium
    (BG)?[0-9]{9,10} |                            # Bulgaria
    (CY)?[0-9]{8}L |                              # Cyprus
    (CZ)?[0-9]{8,10} |                            # Czech Republic
    (DE)?[0-9]{9} |                               # Germany
    (DK)?[0-9]{8} |                               # Denmark
    (EE)?[0-9]{9} |                               # Estonia
    (EL|GR)?[0-9]{9} |                            # Greece
    (ES)?[0-9A-Z][0-9]{7}[0-9A-Z] |               # Spain
    (FI)?[0-9]{8} |                               # Finland
    (FR)?[0-9A-Z]{2}[0-9]{9} |                    # France
    (GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3}) | # United Kingdom
    (HU)?[0-9]{8} |                               # Hungary
    (IE)?[0-9]S[0-9]{5}L |                        # Ireland
    (IT)?[0-9]{11} |                              # Italy
    (LT)?([0-9]{9}|[0-9]{12}) |                   # Lithuania
    (LU)?[0-9]{8} |                               # Luxembourg
    (LV)?[0-9]{11} |                              # Latvia
    (MT)?[0-9]{8} |                               # Malta
    (NL)?[0-9]{9}B[0-9]{2} |                      # Netherlands
    (PL)?[0-9]{10} |                              # Poland
    (PT)?[0-9]{9} |                               # Portugal
    (RO)?[0-9]{2,10} |                            # Romania
    (SE)?[0-9]{12} |                              # Sweden
    (SI)?[0-9]{8} |                               # Slovenia
    (SK)?[0-9]{10}                                # Slovakia
    )$
  */
  vat: value => /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/.test(String(value).toUpperCase()),

  email: value => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value),

  date: value => typeof value.getTime === 'function' && typeof value.getMonth === 'function' && typeof value.getYear === 'function',

  ip: value => types.ipv4(value) || types.ipv6(value),

  ipv4: value => /^(?:\d{1,3}(?:\.|$)){4}/.test(value),

  ipv6: value => (/^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/.test(value)),

  // YYYY-MM-DD HH:mm:ss
  mysqlDateTimeString: value => /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01]) (2[0-3]|[01]?[0-9]):([0-5]?[0-9]):([0-5]?[0-9])$/.test(value),

  // YYYY-MM-DD or YYYY-MM-DD HH:mm:ss
  mysqlDateOrDateTimeString: value => types.mysqlDateString(value) || types.mysqlDateTimeString(value),

  // YYYY-MM-DD
  mysqlDateString: value => /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])$/.test(value),

  // HH:mm:ss
  mysqlTimeString: value => /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9]):([0-5]?[0-9])$/.test(value),

  // HH:mm
  timeString: value => /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/.test(value),

  // DD-MM-YYYY
  dateStringDash: value => /^(0[1-9]|1\d|2\d|3[01])-(0[1-9]|1[0-2])-(19|20)\d{2}$/.test(value),

  // DD/MM/YYYY
  dateStringSlash: value => /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/.test(value)
}

export const type = (rule, value) => typeUtils.isString(rule) ? types[rule](value) : value instanceof rule

export const required = (rule, value) => rule === false ? true : !(value === undefined || value === null || value === '')

export function requiredIf (rule, value, data) {
  if (!data) return true
  const [ruleProperty, rulePropertyValue] = rule
  const propertyValue = ruleProperty.includes('.') ? pathToValue(ruleProperty, data) : data[ruleProperty]
  return rulePropertyValue === propertyValue
}

export function requiredIfNot (rule, value, data) {
  if (!data) return true
  const [ruleProperty, rulePropertyValue] = rule
  const propertyValue = ruleProperty.includes('.') ? pathToValue(ruleProperty, data) : data[ruleProperty]
  return rulePropertyValue !== propertyValue
}

export const regexp = (rule, value) => (new RegExp(rule)).test(value) !== false

export const min = (rule, value) => typeof value !== 'number' ? false : value >= rule

export const max = (rule, value) => typeof value !== 'number' ? false : value <= rule

export const between = (rule, value) => min(rule[0], value) && max(rule[1], value)

export const minLen = (rule, value) => {
  if (typeUtils.isNumber(value)) {
    value = String(value)
  } else if (!typeUtils.isString(value) && !typeUtils.isArray(value)) {
    return false
  }
  return value.length >= rule
}

export const maxLen = (rule, value) => {
  if (typeUtils.isNumber(value)) {
    value = String(value)
  } else if (!typeUtils.isString(value) && !typeUtils.isArray(value)) {
    return false
  }
  return value.length <= rule
}

export const betweenLen = (rule, value) => minLen(rule[0], value) && maxLen(rule[1], value)

export const length = (rule, value) => {
  if (typeUtils.isNumber(value)) {
    value = String(value)
  } else if (!typeUtils.isString(value) && !typeUtils.isArray(value)) {
    return false
  }
  return value.length === rule
}

export const equals = (rule, value, data) => {
  if (rule.includes('|')) rule = rule.split('|')[0]
  const value2 = pathToValue(rule, data)
  return JSON.stringify(sortObjectDeepByKey(value)) === JSON.stringify(sortObjectDeepByKey(value2))
}

export const allValid = (rule, value) => rule ? [value].every(Boolean) : ![value].every(Boolean)
export const anyValid = (rule, value) => rule ? [value].some(Boolean) : ![value].some(Boolean)

export const is = (rule, value) => rule === value
export const isNot = (rule, value) => rule !== value
export const isIn = (rule, value) => rule.indexOf(value) !== -1
export const isNotIn = (rule, value) => rule.indexOf(value) <= -1
export const isNotEmpty = (rule, value) => typeof value === 'number' || typeof value === 'boolean' || !!value

export const dateTimeFormat = (rule, value) => {
  if (rule === 'DD/MM/YYYY') return types.dateStringSlash(value)
  if (rule === 'DD-MM-YYYY') return types.dateStringDash(value)
  if (rule === 'HH:mm') return types.timeString(value)
  if (rule === 'HH:mm:ss') return types.mysqlTimeString(value)
  if (rule === 'YYYY-MM-DD') return types.mysqlDateString(value)
  if (rule === 'YYYY-MM-DD HH:mm:ss') return types.mysqlDateTimeString(value)
  if (rule === 'YYYY-MM-DD HH:mm:ss') return types.mysqlDateTimeString(value)
  return false
}
