import Vue     from 'vue'
import VueI18n from 'vue-i18n'
import dayjs   from 'dayjs'
import en      from './locale/en/Index'
import 'dayjs/locale/en'

Vue.use(VueI18n)

export const languages = [
  {
    active    : true,
    visible   : true,
    name      : 'English',
    short_name: 'EN',
    locale    : 'en',
    code      : 'en-GB',
    code2     : 'en_GB',
    icon      : '/img/flags/1x1/gb.svg'
  }
]

const messages = {
  en: en
}

export const i18n = new VueI18n({
  locale               : process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale       : process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: process.env.NODE_ENV === 'production',
  messages
})
i18n.languages = languages

export const setI18nLanguage = (locale) => {
  const lang = languages.find(l => l.locale === locale) || languages[0]
  i18n.locale = lang.locale
  i18n.code = lang.code
  i18n.code2 = lang.code2
  i18n.name = lang.name
  i18n.short_name = lang.short_name
  i18n.active = lang.active
  if (dayjs) dayjs.locale(lang.locale)
  if (window.Vue) window.Vue.$vuetify.lang.current = lang.locale
  document.querySelector('html').setAttribute('lang', lang.locale)
  return lang
}

// Hot Reloading Language Updates
if (module.hot) {
  module.hot.accept(
    [
      './locale/en/Index'
    ], function () {
      i18n.setLocaleMessage('en', require('./locale/en/Index').default)
    })
}

export default i18n
