<template>
  <div
    :style="`width: ${size}px; height: ${size}px;`"
    class="svg-wrapper"
  >
    <template v-if="name==='home'">
      <svg
        v-if="!active"
        :style="`width: ${parseInt(size)-6}px; height: auto;`"
        viewBox="0 0 19 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            :style="`stroke: ${color};`"
            d="M469,326.87v9.64a.68.68,0,0,0,.19.47.64.64,0,0,0,.45.19h3.86v-5.56a1,1,0,0,1,.28-.69,1,1,0,0,1,.69-.29h3.21a.92.92,0,0,1,.68.29,1,1,0,0,1,.28.69v5.56h3.86A.66.66,0,0,0,483,337a.67.67,0,0,0,.18-.47v-9.64"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            transform="translate(-466.6 -319.67)"
          />
          <path
            :style="`stroke: ${color};`"
            d="M485.1,328.67l-8.56-8.34a.66.66,0,0,0-.87,0l-8.57,8.34m14.79-3.15v-4.7H480v2.82"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            transform="translate(-466.6 -319.67)"
          />
        </g>
      </svg>

      <svg
        v-else
        :style="`width: ${parseInt(size)-6}px; height: auto;`"
        viewBox="0 0 18 17"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          :style="`fill: ${color};`"
          d="M503,322.8a.29.29,0,0,0-.21-.09.27.27,0,0,0-.2.09l-6.91,6.67a.36.36,0,0,0-.07.1.5.5,0,0,0,0,.12V336a1.21,1.21,0,0,0,.35.86,1.18,1.18,0,0,0,.85.36h3.6a.64.64,0,0,0,.43-.18.63.63,0,0,0,.17-.43V331.4a.31.31,0,0,1,.09-.22.33.33,0,0,1,.21-.09h3a.31.31,0,0,1,.3.31v5.16a.61.61,0,0,0,.6.61h3.6a1.18,1.18,0,0,0,.85-.36A1.21,1.21,0,0,0,510,336v-6.26a.25.25,0,0,0,0-.12.36.36,0,0,0-.07-.1Z"
          transform="translate(-493.81 -320.17)"
        />
        <path
          :style="`fill: ${color};`"
          d="M511.62,328.22l-2.81-2.72v-4.12a.61.61,0,0,0-.17-.43.62.62,0,0,0-.43-.18h-1.8a.61.61,0,0,0-.6.61v1.21l-2.17-2.1a1.17,1.17,0,0,0-.83-.32,1.19,1.19,0,0,0-.83.32l-8,7.73a.62.62,0,0,0-.05.84.48.48,0,0,0,.2.15.46.46,0,0,0,.23.06.5.5,0,0,0,.44-.17l7.78-7.52a.32.32,0,0,1,.42,0l7.78,7.52a.55.55,0,0,0,.43.17.6.6,0,0,0,.42-.18A.63.63,0,0,0,511.62,328.22Z"
          transform="translate(-493.81 -320.17)"
        />
      </svg>
    </template>

    <template v-else-if="name==='alerts'">
      <svg
        v-if="!active"
        :style="`width: ${parseInt(size)-9}px; height: auto;`"
        viewBox="0 0 16 19"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          :style="`stroke: ${color};`"

          d="M478.83,367.31V368a2.81,2.81,0,0,1-.8,2,2.69,2.69,0,0,1-3.85,0,2.81,2.81,0,0,1-.8-2v-.69m10-1.41c-1.09-1.36-1.86-2-1.86-5.8,0-3.44-1.73-4.66-3.15-5.25a.76.76,0,0,1-.42-.46,1.9,1.9,0,0,0-3.75,0,.76.76,0,0,1-.42.46c-1.42.59-3.15,1.81-3.15,5.25,0,3.75-.77,4.44-1.87,5.8a.88.88,0,0,0,.74,1.41h13.15A.87.87,0,0,0,483.41,365.9Z"
          style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
          transform="translate(-468.1 -352.27)"
        />
      </svg>

      <svg
        v-else
        :style="`width: ${parseInt(size)-9}px; height: auto;`"
        viewBox="0 0 15 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          :style="`fill: ${color};`"
          d="M510,365.2l-.2-.24c-.85-1.07-1.37-1.72-1.37-4.74a6.25,6.25,0,0,0-1.09-3.82,5.06,5.06,0,0,0-2.18-1.65l0,0a2.34,2.34,0,0,0-4.62,0l0,0c-2.2.93-3.27,2.72-3.27,5.47,0,3-.52,3.67-1.38,4.74a2.51,2.51,0,0,0-.19.24,1.37,1.37,0,0,0-.31.72,1.46,1.46,0,0,0,1.47,1.63h12a1.47,1.47,0,0,0,1.34-.84,1.49,1.49,0,0,0,.12-.78A1.44,1.44,0,0,0,510,365.2Zm-7.19,5.57a3,3,0,0,0,1.6-.46,3.1,3.1,0,0,0,1.15-1.23.31.31,0,0,0,0-.08.19.19,0,0,0,0-.08.34.34,0,0,0-.06-.06l-.08,0H500.2l-.08,0a.1.1,0,0,0-.05.06.11.11,0,0,0,0,.08.31.31,0,0,0,0,.08,3.24,3.24,0,0,0,1.15,1.23A3,3,0,0,0,502.81,370.77Z"
          transform="translate(-495.31 -352.77)"
        />
      </svg>
    </template>

    <template v-else-if="name==='risk'">
      <svg
        v-if="!active"
        :style="`width: ${parseInt(size)-6}px; height: auto;`"
        viewBox="0 0 19 19"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            :style="`stroke: ${color};`"
            d="M476.11,387.78l.69,0a8.31,8.31,0,1,1-7.92,10.84"
            style="stroke-miterlimit : 10; fill: none; stroke-linecap : round;"
            transform="translate(-466.6 -385.87)"
          />
          <path
            :style="`stroke: ${color};`"
            d="M476.1,386.37a9,9,0,0,0-8.21,12.68l8.21-3.68Z"
            style="stroke-linejoin : round; fill: none; stroke-linecap : round;"
            transform="translate(-466.6 -385.87)"
          />
        </g>
      </svg>

      <svg
        v-else
        :style="`width: ${parseInt(size)-6}px; height: auto;`"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          :style="`fill: ${color};`"
          d="M495.18,400.12a.64.64,0,0,1-.35-.1.63.63,0,0,1-.23-.28,9,9,0,0,1,3.91-11.58,9,9,0,0,1,4.3-1.1.66.66,0,0,1,.46.19.65.65,0,0,1,.18.46v8.35a.64.64,0,0,1-.38.59l-7.62,3.41A.66.66,0,0,1,495.18,400.12Z"
          transform="translate(-493.81 -387.06)"
        />
        <path
          :style="`fill: ${color};`"
          d="M505.13,388.52H505l-.12.06a.31.31,0,0,0-.09.11.35.35,0,0,0,0,.14v7.23a1.93,1.93,0,0,1-.31,1.05,1.85,1.85,0,0,1-.83.71l-6.93,3.11a.21.21,0,0,0-.11.08.47.47,0,0,0-.07.12.66.66,0,0,0,0,.14.31.31,0,0,0,.06.13,8.5,8.5,0,0,0,1.94,2,8.36,8.36,0,1,0,6.65-14.91Z"
          transform="translate(-493.81 -387.06)"
        />
      </svg>
    </template>

    <template v-else-if="name==='hedging'">
      <svg
        v-if="!active"
        :style="`width: ${parseInt(size)-8}px; height: auto;`"
        viewBox="0 0 17 19"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          :style="`stroke: ${color};`"

          d="M484.07,422.76a25.22,25.22,0,0,1-8-2.79,25.08,25.08,0,0,1-8,2.79c-.63,11.11,7.37,14.95,8,15.21C476.7,437.71,484.69,433.87,484.07,422.76Z"
          style="fill: none; stroke-linecap: round; stroke-linejoin: round; isolation: isolate;"
          transform="translate(-467.6 -419.47)"
        />
      </svg>

      <svg
        v-else
        :style="`width: ${parseInt(size)-8}px; height: auto;`"
        viewBox="0 0 16 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          :style="`fill: ${color};`"
          d="M510.63,424.16a.67.67,0,0,1,.15.39,17.7,17.7,0,0,1-.87,6.8,14.6,14.6,0,0,1-2.56,4.52,13,13,0,0,1-4.32,3.44.54.54,0,0,1-.43,0,12.93,12.93,0,0,1-4.33-3.44,14.26,14.26,0,0,1-2.55-4.52,17.7,17.7,0,0,1-.88-6.8.67.67,0,0,1,.15-.39.5.5,0,0,1,.32-.2,23.43,23.43,0,0,0,7.27-2.54.46.46,0,0,1,.23-.06.53.53,0,0,1,.24.06,23.28,23.28,0,0,0,7.26,2.54A.5.5,0,0,1,510.63,424.16Z"
          transform="translate(-494.81 -421.36)"
        />
      </svg>
    </template>

    <template v-else-if="name==='industry'">
      <svg
        v-if="!active"
        :style="`width: ${parseInt(size)-6}px; height: auto;`"
        viewBox="0 0 19 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            :style="`stroke: ${color};`"
            d="M467.1,468.36h18"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            transform="translate(-466.6 -453.08)"
          />
          <path
            :style="`stroke: ${color};`"
            d="M477.39,468.36V454.22a.64.64,0,0,0-.19-.45.6.6,0,0,0-.45-.19H469a.6.6,0,0,0-.45.19.64.64,0,0,0-.19.45v14.14"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            transform="translate(-466.6 -453.08)"
          />
          <path
            :style="`stroke: ${color};`"
            d="M483.82,468.36v-9a.65.65,0,0,0-.65-.64h-5.78"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            transform="translate(-466.6 -453.08)"
          />
          <path
            :style="`stroke: ${color};`"
            d="M471,456.79h2.57"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            transform="translate(-466.6 -453.08)"
          />
          <path
            :style="`stroke: ${color};`"
            d="M472.25,461.93h2.57"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            transform="translate(-466.6 -453.08)"
          />
          <path
            :style="`stroke: ${color};`"
            d="M471,465.15h2.57"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            transform="translate(-466.6 -453.08)"
          />
          <path
            :style="`stroke: ${color};`"
            d="M480,465.15h1.29"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            transform="translate(-466.6 -453.08)"
          />
          <path
            :style="`stroke: ${color};`"
            d="M480,461.93h1.29"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            transform="translate(-466.6 -453.08)"
          />
        </g>
      </svg>

      <svg
        v-else
        :style="`width: ${parseInt(size)-6}px; height: auto;`"
        viewBox="0 0 18 15"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          :style="`fill: ${color};`"
          d="M511.21,467.27h-.6v-7.8a1.2,1.2,0,0,0-1.2-1.2h-4.8v-3.6a1.2,1.2,0,0,0-1.2-1.2h-7.2a1.2,1.2,0,0,0-1.2,1.2v12.6h-.6a.6.6,0,0,0,0,1.2h16.8a.6.6,0,0,0,0-1.2Zm-9-5.4a.62.62,0,0,1-.6.6h-2.4a.6.6,0,0,1,0-1.2h2.4a.62.62,0,0,1,.6.6Zm-4.2-5.4h2.4a.6.6,0,0,1,0,1.2H498a.6.6,0,0,1,0-1.2Zm0,7.8h2.4a.6.6,0,0,1,0,1.2H498a.6.6,0,0,1,0-1.2Zm6.6-4.8h4.8v7.8h-4.8Z"
          transform="translate(-493.81 -453.47)"
        />
        <path
          :style="`fill: ${color};`"
          d="M507.61,464.27h-1.2a.6.6,0,0,0,0,1.2h1.2a.6.6,0,0,0,0-1.2Z"
          transform="translate(-493.81 -453.47)"
        />
        <path
          :style="`fill: ${color};`"
          d="M506.41,462.47h1.2a.6.6,0,0,0,0-1.2h-1.2a.6.6,0,0,0,0,1.2Z"
          transform="translate(-493.81 -453.47)"
        />
      </svg>
    </template>

    <template v-else-if="name==='positions'">
      <svg
        v-if="!active"
        :style="`width: ${parseInt(size)-4}px; height: auto;`"
        viewBox="0 0 20 11"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            :style="`fill: ${color};`"
            d="M483.93,486.14a.45.45,0,0,1,0,.61l-3.76,3.76a.45.45,0,0,1-.61,0,.43.43,0,0,1,0-.61l3.76-3.76A.43.43,0,0,1,483.93,486.14Zm-10.6,1a.42.42,0,0,1,0,.6l-4.45,4.45a.43.43,0,0,1-.6,0,.43.43,0,0,1,0-.61l4.44-4.44A.43.43,0,0,1,473.33,487.17Zm1.45,0a.42.42,0,0,1,.6,0l2.73,2.73a.43.43,0,0,1,0,.61.43.43,0,0,1-.6,0l-2.73-2.74A.42.42,0,0,1,474.78,487.17Z"
            style="fill-rule : evenodd;"
            transform="translate(-466.1 -483.97)"
          />
          <path
            :style="`fill: ${color};`"
            d="M484.65,484.82a.6.6,0,1,0,.6.6A.6.6,0,0,0,484.65,484.82Zm-1.45.6a1.45,1.45,0,1,1,1.45,1.45A1.45,1.45,0,0,1,483.2,485.42Z"
            style="fill-rule : evenodd;"
            transform="translate(-466.1 -483.97)"
          />
          <path
            :style="`fill: ${color};`"
            d="M478.84,490.63a.6.6,0,1,0,.6.6A.6.6,0,0,0,478.84,490.63Zm-1.45.6a1.45,1.45,0,1,1,1.45,1.45A1.45,1.45,0,0,1,477.39,491.23Z"
            style="fill-rule : evenodd;"
            transform="translate(-466.1 -483.97)"
          />
          <path
            :style="`fill: ${color};`"
            d="M474.05,485.85a.59.59,0,0,0-.6.59.6.6,0,1,0,1.2,0A.59.59,0,0,0,474.05,485.85Zm-1.45.59a1.45,1.45,0,1,1,1.45,1.46A1.45,1.45,0,0,1,472.6,486.44Z"
            style="fill-rule : evenodd;"
            transform="translate(-466.1 -483.97)"
          />
          <path
            :style="`fill: ${color};`"
            d="M467.56,492.34a.6.6,0,0,0,0,1.2.6.6,0,0,0,0-1.2Zm-1.46.6a1.46,1.46,0,1,1,1.46,1.45A1.45,1.45,0,0,1,466.1,492.94Z"
            style="fill-rule : evenodd;"
            transform="translate(-466.1 -483.97)"
          />
        </g>
      </svg>

      <svg
        v-else
        :style="`width: ${parseInt(size)-4}px; height: auto;`"
        viewBox="0 0 20 11"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          :style="`fill: ${color};`"
          d="M509.34,483a1.76,1.76,0,0,0-.78.19,1.61,1.61,0,0,0-.59.54,1.56,1.56,0,0,0-.28.75,1.69,1.69,0,0,0,.1.8l-3.51,3.5a1.68,1.68,0,0,0-1.21,0l-2.51-2.5a1.69,1.69,0,0,0,.11-.8,1.67,1.67,0,0,0-.28-.75,1.72,1.72,0,0,0-.6-.54,1.7,1.7,0,0,0-1.56,0,1.72,1.72,0,0,0-.6.54,1.67,1.67,0,0,0-.28.75,1.69,1.69,0,0,0,.11.8l-4.17,4.17a1.66,1.66,0,0,0-2,.64,1.65,1.65,0,0,0-.26,1.08,1.67,1.67,0,0,0,1.49,1.49,1.71,1.71,0,0,0,1.08-.26,1.69,1.69,0,0,0,.64-2l4.17-4.17a1.71,1.71,0,0,0,1.22,0l2.51,2.51a1.64,1.64,0,0,0-.11.79,1.67,1.67,0,0,0,.28.75,1.68,1.68,0,0,0,2.16.54,1.61,1.61,0,0,0,.59-.54,1.56,1.56,0,0,0,.28-.75,1.63,1.63,0,0,0-.1-.79l3.5-3.51a1.62,1.62,0,0,0,.72.11,1.83,1.83,0,0,0,.7-.2,1.69,1.69,0,0,0,.54-.49A1.46,1.46,0,0,0,511,485a1.64,1.64,0,0,0,0-.73,1.74,1.74,0,0,0-.33-.65,1.79,1.79,0,0,0-.58-.44A1.57,1.57,0,0,0,509.34,483Z"
          transform="translate(-491.01 -482.99)"
        />
      </svg>
    </template>

    <template v-else-if="name==='knowledge-center'">
      <svg
        v-if="!active"
        :style="`width: ${parseInt(size)-4}px; height: auto;`"
        viewBox="0 0 21 19"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            :style="`stroke: ${color};`"
            d="M476.44,527.14,470,523.57v-5.72l6.42,3.57m0,5.72,6.43-3.57v-5.72l-6.43,3.57m0,5.72v-5.72m10,2.15v-7.86"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            transform="translate(-465.94 -509.5)"
          />
          <path
            :style="`stroke: ${color};`"
            d="M466.44,515.71l10-5.71,10,5.71-10,5.71Z"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            transform="translate(-465.94 -509.5)"
          />
        </g>
      </svg>

      <svg
        v-else
        :style="`width: ${parseInt(size)-4}px; height: auto;`"
        viewBox="0 0 20 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          :style="`fill: ${color};`"
          d="M501.66,524.25a.76.76,0,0,1-.33-.08l-5.83-3.3a.31.31,0,0,0-.34,0,.34.34,0,0,0-.12.12.32.32,0,0,0,0,.16v3.1a.67.67,0,0,0,.09.34.72.72,0,0,0,.25.24l6,3.3a.72.72,0,0,0,.32.08.73.73,0,0,0,.33-.08l6-3.3a.72.72,0,0,0,.25-.24.67.67,0,0,0,.09-.34v-3.1a.47.47,0,0,0,0-.16.37.37,0,0,0-.13-.12.33.33,0,0,0-.16,0,.34.34,0,0,0-.17,0l-5.84,3.3A.73.73,0,0,1,501.66,524.25Z"
          transform="translate(-491.67 -511.07)"
        />
        <path
          :style="`fill: ${color};`"
          d="M511.66,516.94h0a.63.63,0,0,0-.1-.29.66.66,0,0,0-.23-.21L502,511.16a.66.66,0,0,0-.33-.09.63.63,0,0,0-.33.09L492,516.43a.69.69,0,0,0-.24.24.65.65,0,0,0,0,.66.62.62,0,0,0,.24.24l9.34,5.28a.73.73,0,0,0,.33.08.76.76,0,0,0,.33-.08l8.21-4.64h.08l0,0s0,0,0,0v6a.68.68,0,0,0,.64.68.57.57,0,0,0,.26,0,.68.68,0,0,0,.23-.14.54.54,0,0,0,.15-.22.58.58,0,0,0,.06-.26V517A.13.13,0,0,0,511.66,516.94Z"
          transform="translate(-491.67 -511.07)"
        />
      </svg>
    </template>

    <template v-else-if="name==='help'">
      <svg
        v-if="!active"
        :style="`width: ${parseInt(size)-6}px; height: auto;`"
        viewBox="0 0 19 19"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            :style="`stroke: ${color};`"
            class="cls-2"
            d="M477.39,542.74a9,9,0,1,0,6.36,2.64A9,9,0,0,0,477.39,542.74Z"
            style="fill: none; stroke-miterlimit : 10;"
            transform="translate(-467.89 -542.24)"
          />
          <path
            :style="`stroke: ${color};`"
            class="cls-3"
            d="M474.53,549a2.48,2.48,0,0,1,1-1.66,3,3,0,0,1,1.86-.6,3.14,3.14,0,0,1,1.36.23,2.33,2.33,0,0,1,1.5,2.1c0,1.33-.87,1.93-1.86,2.6a2.41,2.41,0,0,0-1.26,2.13"
            style="fill: none; stroke-miterlimit : 10; stroke-linecap: round; stroke-width: 1.5px;"
            transform="translate(-467.89 -542.24)"
          />
          <path
            :style="`fill: ${color};`"
            d="M477.39,557.47a.82.82,0,1,0-.82-.82A.82.82,0,0,0,477.39,557.47Z"
            transform="translate(-467.89 -542.24)"
          />
        </g>
      </svg>

      <svg
        v-else
        :style="`width: ${parseInt(size)-6}px; height: auto;`"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          :style="`fill: ${color};`"
          d="M501.39,542.74a9,9,0,1,0,9,9A9,9,0,0,0,501.39,542.74ZM501.1,557a.91.91,0,0,1-.52-.16.94.94,0,0,1-.34-.42,1,1,0,0,1-.06-.54,1,1,0,0,1,.74-.74,1,1,0,0,1,.54.06.94.94,0,0,1,.42.34.91.91,0,0,1,.16.52.92.92,0,0,1-.94.94Zm1.57-4.78a1.55,1.55,0,0,0-.86,1.41.66.66,0,0,1-.19.46.68.68,0,0,1-.47.19.66.66,0,0,1-.46-.19.63.63,0,0,1-.2-.46,2.89,2.89,0,0,1,1.45-2.5c.9-.61,1.41-1,1.41-1.84a1.45,1.45,0,0,0-1-1.33,2.49,2.49,0,0,0-1-.15,2,2,0,0,0-1.3.41,1.66,1.66,0,0,0-.67,1,.78.78,0,0,1-.07.25.55.55,0,0,1-.15.2.61.61,0,0,1-.22.13.55.55,0,0,1-.25,0,.54.54,0,0,1-.25-.06.8.8,0,0,1-.21-.15,1,1,0,0,1-.13-.22.75.75,0,0,1,0-.26,2.94,2.94,0,0,1,1.17-2,3.3,3.3,0,0,1,2.1-.7,3.78,3.78,0,0,1,1.54.28,2.75,2.75,0,0,1,1.76,2.51C504.67,550.87,503.61,551.58,502.67,552.21Z"
          transform="translate(-492.39 -542.74)"
        />
      </svg>
    </template>
  </div>
</template>

<script>
export default {
  name : 'SynthesisIcon',
  props: {
    name: {
      type   : String,
      default: ''
    },
    color: {
      type   : String,
      default: '#ffffff'
    },
    size: {
      type   : [String, Number],
      default: 24
    },
    active: {
      type   : Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.svg-wrapper {
  display     : flex;
  align-items : center;
  height      : 100%;
}

.svg-wrapper > svg {
  display    : block;
  margin     : 0 auto;
  text-align : center;
}
</style>
