/* eslint-disable no-empty,no-unused-vars */
/**
 * Imports a component based on selected theme and fallbacks to default theme for component if component not found
 *
 * @param path The path of the component to import, after theme path
 * @returns {Promise<void>}
 */
import DataStore from '../../data/dataStore/DataStore'

export const importThemeComponent = async (path) => {
  const ACTIVE_THEME = DataStore.App.Theme.Active || process.env.VUE_APP_ACTIVE_THEME
  const DEFAULT_THEME = DataStore.App.Theme.Default || process.env.VUE_APP_DEFAULT_THEME

  try {
    return await import(
      /* webpackMode: "lazy" */
      /* webpackMode: "eager" */
      /* webpackChunkName: "[request]" */
      `@/theme/${ ACTIVE_THEME }/${ path }`
    )
  } catch (e) {}

  try {
    return await import(
      /* webpackMode: "lazy" */
      /* webpackMode: "eager" */
      /* webpackChunkName: "[request]" */
      `@/theme/${ DEFAULT_THEME }/${ path }`
    )
  } catch (e) {}
}
