import Common  from './Common'
import Route   from './Route'
import Home    from './Home'
import Contact from './Contact'

export default {
  Common : Common,
  Route  : Route,
  Home   : Home,
  Contact: Contact
}
