export default {
  anchor   : '#2196F3',
  primary  : '#3D68D5', // '#1976D2',
  secondary: '#424242',
  accent   : '#82B1FF',
  error    : '#FF5252',
  info     : '#2196F3',
  success  : '#4CAF50',
  warning  : '#FB8C00',
  synthesis: '#11224E',

  'synthesis-brand-dark-blue'    : '#041F30',
  'synthesis-brand-light-blue'   : '#6E8EDF',
  'synthesis-brand-lightest-blue': '#F4F7FF',
  'synthesis-brand-white'        : '#FFFFFF',
  'synthesis-brand-red'          : '#E52C2C',
  'synthesis-brand-yellow'       : '#FFB21E',
  'synthesis-brand-off-white'    : '#F3F3EC',

  'synthesis-ui-blue-01': '#11224E',
  'synthesis-ui-blue-02': '#1C3880',
  'synthesis-ui-blue-03': '#274EB0',
  'synthesis-ui-blue-04': '#3D68D5',
  'synthesis-ui-blue-05': '#6E8EDF',
  'synthesis-ui-blue-06': '#9EB4EA',
  'synthesis-ui-blue-07': '#CFD9F4',
  'synthesis-ui-blue-08': '#E7ECFA',

  'synthesis-ui-red-00': '#9A2525',
  'synthesis-ui-red-01': '#E42525',
  'synthesis-ui-red-02': '#E94C4C',
  'synthesis-ui-red-03': '#ED6A6A',
  'synthesis-ui-red-04': '#F08888',
  'synthesis-ui-red-05': '#F4A6A6',
  'synthesis-ui-red-06': '#F8C3C3',
  'synthesis-ui-red-07': '#FBE1E1',
  'synthesis-ui-red-08': '#FDF0F0',

  'synthesis-ui-yellow-01': '#FFB21E',
  'synthesis-ui-yellow-02': '#FFBC3F',
  'synthesis-ui-yellow-03': '#FFC75F',
  'synthesis-ui-yellow-04': '#FFD27F',
  'synthesis-ui-yellow-05': '#FFDD9F',
  'synthesis-ui-yellow-06': '#FFE9BF',
  'synthesis-ui-yellow-07': '#FFF4DF',
  'synthesis-ui-yellow-08': '#FFF9EF',

  'synthesis-ui-green-00': '#065D02',
  'synthesis-ui-green-01': '#4CAF50',
  'synthesis-ui-green-02': '#57C05B',
  'synthesis-ui-green-03': '#65D069',
  'synthesis-ui-green-04': '#77E47B',
  'synthesis-ui-green-05': '#93DE94',
  'synthesis-ui-green-06': '#B9F9BA',
  'synthesis-ui-green-07': '#CEFDCF',
  'synthesis-ui-green-08': '#EDF7ED',

  'synthesis-ui-purple-01': '#B64AD8',
  'synthesis-ui-purple-02': '#C264DE',
  'synthesis-ui-purple-03': '#CC7EE4',
  'synthesis-ui-purple-04': '#D698E9',
  'synthesis-ui-purple-05': '#E0B1EF',
  'synthesis-ui-purple-06': '#EBCBF4',
  'synthesis-ui-purple-07': '#F5E5FA',
  'synthesis-ui-purple-08': '#FAF2FC',

  'synthesis-ui-orange-01': '#E66B2C',
  'synthesis-ui-orange-02': '#EA804B',
  'synthesis-ui-orange-03': '#ED9569',
  'synthesis-ui-orange-04': '#F1AA87',
  'synthesis-ui-orange-05': '#F4BFA5',
  'synthesis-ui-orange-06': '#F8D5C3',
  'synthesis-ui-orange-07': '#FBEAE1',
  'synthesis-ui-orange-08': '#FDF4F0',

  'synthesis-ui-grey-01': '#63747D',
  'synthesis-ui-grey-02': '#DFE3EB',
  'synthesis-ui-grey-03': '#ECECEF',
  'synthesis-ui-grey-04': '#EFF1F3',
  'synthesis-ui-grey-05': '#F2F5F8',
  'synthesis-ui-grey-06': '#F7F9FC'
}
